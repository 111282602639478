import { AppointmentType, ServiceType } from "./nexhealthModels";

import { PolicyMap } from "./nexhealthModels";

export enum Role {
  Admin = "Admin",
  Collaborator = "Collaborator",
}

export interface Invite {
  email: string;
  role: Role;
}

export interface Clinic {
  id: string;
  info: { name: string; address: string; manager: string };
  preferences: {
    policyMap: PolicyMap;
    callGreeting: {
      clinicName: string;
      receptionistName: string;
      missedCallRecordingURL: string;
      voicemailRecordingURL: string;
    };
  };
  pmsIntegrationDetails: PmsIntegrationDetails;
  phoneNumberIntegrationDetails: PhoneNumberIntegrationDetails;
  forwardingClinicNumber: string;
  status: ClinicStatus;
}

export interface Provider {
  clinicId: string;
  providerId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  nexhealthParameters: {
    institutionId: string;
  };
  serviceConfigurations: {
    [key: string]: { enabled: boolean; duration: number };
  };
  nexhealthProviderId: string;
  availabilities: Availability[];
  acceptingNewPatients: boolean;
  createTimestamp: string;
  editTimestamp: string;
}

export interface Availability {
  availabilityId: string;
  beginTime: string;
  endTime: string;
  operatoryId?: string;
  operatoryName?: string;
  occurrenceType: OccurrenceType;
  specificDate?: SpecificDate;
  recurrence?: Recurrence;
  days?: Day[];
  active: boolean;
  nexhealthAvailabilityId?: string;
}

export type SpecificDate = string;

export enum OccurrenceType {
  "SPECIFIC_DATE" = "SPECIFIC_DATE",
  "RECURRENCE" = "RECURRENCE",
  "DAYS" = "DAYS",
}

export enum Day {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

export interface Recurrence {
  num: number;
  ref: string;
  unit: Unit;
}

export enum Unit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export enum PhoneNumberConnectionStatus {
  NOT_CONNECTED = "NOT_CONNECTED",
  CONNECTED = "CONNECTED",
  PENDING = "PENDING",
}
export interface PmsIntegrationDetails {
  bearerToken: string;
  bearerTokenCreateTimestamp: string;
  subdomain: string;
  locationId: string;
  pmsConnected: boolean;
  lastTestedTimestamp: string;
  tz: string;
  mapByOperatory?: boolean;
  operatories?: Operatory[];
}

export interface Operatory {
  operatoryId: string;
  operatoryName: string;
  active: boolean;
}

export interface PhoneNumberIntegrationDetails {
  baseClinicNumber: string;
  forwardingClinicNumberFriendlyName: string;
  forwardingClinicNumberSid: string;
  phoneNumberStatus: PhoneNumberConnectionStatus;
  lastTestedTimestamp: string;
}

export enum ClinicStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ConversationState {
  RESOLVED = "RESOLVED", // successful action achieved
  OPEN = "OPEN", // current convo is still open
  EXPIRED = "EXPIRED", // convo is expired
  CLOSED = "CLOSED", // convo is closed
}

export enum MessageType { // TODO: rename to MessageType?
  MISSED_CALL = "MISSED_CALL",
  SMS_MESSAGE = "SMS_MESSAGE",
  GPT_RESPONSE = "GPT_RESPONSE",
}

export enum MessageDirection {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
  INTERNAL = "INTERNAL",
}
export interface Message {
  role: "user" | "assistant" | "system" | "function" | "tool";
  content: string | undefined;
  timestamp: string;
  direction: MessageDirection;
  type: MessageType;
  tool_call_id?: string;
  tool_calls?: any;
}

export interface Conversation {
  forwardingClinicNumber_PatientNumber: string;
  createTimestamp: string;
  conversationId: string;
  patientNumber: string;
  forwardingClinicNumber: string;
  tenantId: string;
  clinicId: string;
  messages: Message[];
  Costs: {
    tokenCount: { prompt: number; completion: number };
    smsCount: {
      inbound: number;
      outbound: number;
    };
    nexhealthAPICalls: number;
    finalConversationCost: number;
  };
  state: ConversationState;
  messagesMetaData: {
    patientInfo: {
      patientId: string;
      patientFirstName: string;
      patientLastName: string;
      newPatient: string | undefined;
      patientDateOfBirth: string;
      patientEmail: string;
    };
    providerToCancelInfo: {
      providerFirstName: string;
      providerLastName: string;
      providerId: string;
    };
    providerToScheduleInfo: {
      providerFirstName: string;
      providerLastName: string;
      providerId: string;
    };
    appointmentToCancelInfo: {
      appointmentId: string;
      appointmentType: AppointmentType;
      dateToCancel: string;
    };
    appointmentToScheduleInfo: {
      appointmentId: string;
      appointmentType: AppointmentType;
      dateToSchedule: string;
    };
    additionalInfo: {
      reasonForClosingConversation: string;
      newPatientCreated: boolean | undefined;
      serviceType: ServiceType;
    };
  };
  llmVersion: string;
  errors: string[];
  editTimestamp: string;
}

export const unknownMember: any = { id: "", name: "", email: "", role: "" };

interface TenantPayload {
  tenantId: string;
  tenantName: string;
}
export interface User {
  id: string;
  name: string;
  email: string;
  tenant: TenantPayload;
  defaultClinicId?: string;
}

export const defaultUser: User = {
  id: "",
  name: "",
  email: "",
  tenant: null,
  defaultClinicId: "",
};

export interface Member {
  id: string;
  name: string;
  email: string;
  role: Role;
}

export enum CallbackState {
  PENDING = "PENDING", // Callback request received and waiting to be processed
  COMPLETED = "COMPLETED", // Callback was completed successfully
  CANCELLED = "CANCELLED", // Callback was canceled either by the patient or the system
  ATTEMPTED = "ATTEMPTED", // Callback was attempted but did not connect, may try again
}

export interface Callback {
  tenantId: string;
  callbackId: string;
  clinicId: string;
  conversationId: string;
  patientNumber: string;
  patientFirstName: string;
  patientLastName: string;
  reason: string;
  preferredCallbackTime: string;
  newPatient?: boolean;
  state: CallbackState;
  createTimestamp: string;
  patientEmail: string;
  patientDateOfBirth: string;
  notes: string;
  editTimestamp: string;
}

export enum VoicemailState {
  NEW = "NEW", // Voicemail is new and needs to be checked
  REVIEWED = "REVIEWED", // Voicemail has been listened to and actions are being planned
  COMPLETED = "COMPLETED", // Necessary actions (e.g., scheduling, follow-up) based on the voicemail have been completed
  ARCHIVED = "ARCHIVED", // Voicemail has been handled and archived for records
}

export interface Voicemail {
  tenantId: string;
  voicemailId: string;
  clinicId: string;
  patientNumber: string;
  recording: {
    s3BucketKey: string;
    duration: number;
    transcription: string;
    signedURL: string;
  };
  state: VoicemailState;
  notes: string;
  createTimestamp: string;
  editTimestamp: string;
}
