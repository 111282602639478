import { Callback, CallbackState, ClinicStatus } from "src/libs/models";

import { RefreshReturn } from "../queries/useCallbacks";
import { authedPost } from "src/libs/aws";
import { createId } from "src/libs/misc";
import { produce } from "immer";
import { useOptimisticUpdate } from "src/libs/query";
import { useTenantId } from "src/pages/tenant/queries";

interface CreateCallbackProps {
  clinicId: string;
  conversationId: string;
  patientPhoneNumber: string;
}

const createCallbackAction =
  (tenantId: string, callbackId: string) =>
  async ({
    clinicId,
    conversationId,
    patientPhoneNumber,
  }: CreateCallbackProps): Promise<any> => {
    return authedPost("/callbacks", {
      action: "create",
      tenantId,
      clinicId,
      conversationId,
      patientPhoneNumber,
      callbackId,
    });
  };

export const useCreateCallbackMutation = () => {
  const tenantId: string = useTenantId();
  const callbackId: string = createId();

  return useOptimisticUpdate(
    ["callbacks", tenantId],
    createCallbackAction(tenantId, callbackId),
    ({ clinicId, conversationId, patientPhoneNumber }) =>
      produce((draftCallbacks: RefreshReturn) => {
        const newCallback: Callback = {
          tenantId,
          callbackId,
          clinicId,
          conversationId,
          patientNumber: patientPhoneNumber,
          patientFirstName: "",
          patientLastName: "",
          reason: "Manually Created Callback",
          preferredCallbackTime: "",
          state: CallbackState.PENDING,
          createTimestamp: "",
          patientEmail: "",
          patientDateOfBirth: "",
          notes: "",
          editTimestamp: "",
        };
        draftCallbacks.callbacks.push(newCallback);
      }),
    {
      retry: 3,
      mutationKey: ["clinics", tenantId],
    }
  );
};
