import * as React from "react";

import { AppointmentType, ServiceType } from "src/libs/nexhealthModels";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  Callback,
  Clinic,
  Conversation,
  ConversationState,
  Message,
  MessageDirection,
} from "src/libs/models";
import { formatDate, formatPhoneNumber, truncateText } from "src/libs/misc";
import { useEffect, useMemo, useRef, useState } from "react";

import EmptyState from "src/components/EmptyState";
import FilterListIcon from "@mui/icons-material/FilterList";
import Paper from "@mui/material/Paper";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { callback } from "chart.js/dist/helpers/helpers.core";
import moment from "moment-timezone";
import { useCallbacks } from "../callbacks/queries/useCallbacks";
import { useClinicIdMapping } from "../clinics/queries/useClinicIdMapping";
import { useClinicsList } from "../clinics/queries/useClinicsList";
import { useConversations } from "./queries/useConversations";
import { useCreateCallbackMutation } from "../callbacks/mutations/useCreateCallbacks";
import { useDefaultClinicId } from "../profile/queries/useUser";
import { useNavigate } from "react-router-dom";

// Define a mapping for state colors based on your ConversationState enum
const stateColors = {
  RESOLVED: "#4CAF50",
  OPEN: "#2196F3",
  EXPIRED: "#FFC107",
  CLOSED: "#F44336",
};

const serviceTypeColors = {
  [ServiceType.SCHEDULE_APPOINTMENT]: "#81c784",
  [ServiceType.CANCEL_APPOINTMENT]: "#f78888",
  [ServiceType.RESCHEDULE_APPOINTMENT]: "#f3d250",
  [ServiceType.SCHEDULE_CALLBACK]: "#e0e0e0",
};

const appointmentTypeColors = {
  [AppointmentType.CLEANING]: "#7ebdc2",
  [AppointmentType.CONSULTATION_FOR_ORTHODONTICS]: "#a1c181",
  [AppointmentType.CONSULTATION_FOR_EXTRACTIONS]: "#d6d1b1",
  [AppointmentType.CONSULTATION_FOR_IMPLANTS]: "#ffad87",
  [AppointmentType.CONSULTATION_FOR_DENTURES]: "#c2cad0",
  [AppointmentType.CONSULTATION_FOR_BRIDGE]: "#cad2c5",
  [AppointmentType.CONSULTATION_FOR_GRAFTING]: "#c2b0c9",
  [AppointmentType.CONSULTATION_FOR_COSMETICS]: "#ebc8b2",
  [AppointmentType.EMERGENCY_TREATMENT]: "#b1a7a6",
  [AppointmentType.NEW_PATIENT_EXAM]: "#99ddcc",
};

const patientStatusColors = {
  New: "#4db6ac",
  Existing: "#90a4ae",
};

const additionalInfoColors = {
  patientFullName: "#9e9e9e", // Grey
  patientDateOfBirth: "#81d4fa", // Light Blue
  patientEmail: "#ffcc80", // Light Orange
  providerToCancelFullName: "#ce93d8", // Light Purple
  providerToScheduleFullName: "#a5d6a7", // Light Green
  dateToCancel: "#bcaaa4", // Brown
  dateToSchedule: "#fff59d", // Yellow
  newPatientCreated: "#80cbc4", // Teal
};

const ConversationsTable = () => {
  // basic state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();

  // filtering Info
  const [statusFilter, setStatusFilter] = useState<ConversationState[]>(
    Object.values(ConversationState)
  );
  const [serviceTypeFilter, setServiceTypeFilter] = useState<
    (ServiceType | "Unknown")[]
  >([...Object.values(ServiceType), "Unknown"]);
  const [patientStatusFilter, setPatientStatusFilter] = useState<
    ("New" | "Existing" | "Unknown")[]
  >(["New", "Existing", "Unknown"]);

  const clinics = useClinicsList().data || [];
  // const clinicIds = clinics.map((clinic: Clinic) => clinic.id);
  const defaultClinicId = useDefaultClinicId();
  const clinicIdMapping: {
    [id: string]: { clinicName: string; timeZone: string };
  } = useClinicIdMapping() || {};

  // const [clinicIdsFilter, setClinicIdsFilter] = useState<string[]>([]);
  const clinicIdsInitialized = useRef(false);

  const [sortDirection, setSortDirection] = useState("desc"); // "asc" | "desc"

  // useEffect(() => {
  //   if (clinicIds.length > 0 && !clinicIdsInitialized.current) {
  //     setClinicIdsFilter(clinicIds);
  //     clinicIdsInitialized.current = true;
  //   }
  // }, [clinicIds]);
  // useEffect(() => {
  //   if (clinicIds.length > 0 && !clinicIdsInitialized.current) {
  //     setClinicIdsFilter(clinicIds);
  //     clinicIdsInitialized.current = true;
  //   }
  // }, [clinicIds]);

  // conversations
  const conversations: Conversation[] = useConversations();
  const userConversations = conversations.map((conversation: Conversation) => {
    const filteredMessages = conversation.messages.filter(
      (message: Message) =>
        message.role === "user" ||
        (message.role === "assistant" &&
          message.direction === MessageDirection.OUTGOING)
    );
    return { ...conversation, messages: filteredMessages };
  });

  const filteredConversations = userConversations.filter(
    (conversation: Conversation) => {
      return (
        statusFilter.includes(conversation.state) &&
        serviceTypeFilter.includes(
          (conversation.messagesMetaData.additionalInfo
            .serviceType as ServiceType) || "Unknown"
        ) &&
        patientStatusFilter.includes(
          conversation.messagesMetaData.patientInfo.newPatient === undefined
            ? "Unknown"
            : conversation.messagesMetaData.patientInfo.newPatient
            ? "New"
            : "Existing"
        ) &&
        (defaultClinicId === "ALL" || defaultClinicId === conversation.clinicId)
      );
    }
  );

  const sortedConversations = [...filteredConversations].sort((a, b) => {
    const dateA = new Date(a.editTimestamp);
    const dateB = new Date(b.editTimestamp);

    return sortDirection === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });

  const useCreateCallback = useCreateCallbackMutation();

  const createCallback = async (
    clinicId: string,
    conversationId: string,
    patientPhoneNumber: string
  ): Promise<void> => {
    await useCreateCallback.mutateAsync({
      clinicId,
      conversationId,
      patientPhoneNumber,
    });
  };

  const callbacks: Callback[] = useCallbacks() || [];
  const callbackConversationIdsSet = useMemo(
    () =>
      new Set(callbacks.map((callback: Callback) => callback.conversationId)),
    [callbacks]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  // Handle selecting a filter option
  const handleStatusFilterChange = (status: ConversationState) => {
    setStatusFilter((currentFilters) => {
      const isStatusFiltered = currentFilters.includes(status);
      if (isStatusFiltered) {
        return currentFilters.filter((s) => s !== status);
      } else {
        return [...currentFilters, status];
      }
    });
  };

  if (userConversations.length === 0) {
    return (
      <EmptyState text="No conversations found" sx={{ marginTop: "30px" }} />
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={"1"}
                align="left"
                style={{ maxWidth: "35px", minWidth: "35px" }}
              >
                Patient Number
              </TableCell>
              <TableCell key={"2"} align="left" style={{ maxWidth: "120px" }}>
                Messages
              </TableCell>
              <TableCell key={"3"} align="left" style={{ maxWidth: "75px" }}>
                Context
              </TableCell>
              <TableCell key={"4"} align="left" style={{ maxWidth: "15px" }}>
                Status
                <IconButton
                  aria-label="filter list"
                  onClick={handleStatusMenuClick}
                  size="small"
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  anchorEl={statusAnchorEl}
                  open={Boolean(statusAnchorEl)}
                  onClose={() => setStatusAnchorEl(null)}
                >
                  {Object.values(ConversationState).map((status) => (
                    <MenuItem
                      key={status}
                      onClick={() => handleStatusFilterChange(status)}
                    >
                      <Checkbox checked={statusFilter.indexOf(status) > -1} />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
              <TableCell key={"5"} align="right" style={{ maxWidth: "20px" }}>
                Last active
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedConversations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((conversation: Conversation) => {
                const {
                  newPatient,
                  patientFirstName,
                  patientLastName,
                  patientDateOfBirth,
                  patientEmail,
                } = conversation.messagesMetaData.patientInfo;
                const {
                  appointmentType: appointmentToCancelType,
                  dateToCancel,
                } = conversation.messagesMetaData.appointmentToCancelInfo;
                const {
                  appointmentType: appointmentToScheduleType,
                  dateToSchedule,
                } = conversation.messagesMetaData.appointmentToScheduleInfo;
                const { newPatientCreated, serviceType } =
                  conversation.messagesMetaData.additionalInfo || {};
                const {
                  providerFirstName: providerToCancelFirstName,
                  providerLastName: providerToCancelLastName,
                } = conversation.messagesMetaData.providerToCancelInfo || {};
                const {
                  providerFirstName: providerToScheduleFirstName,
                  providerLastName: providerToScheduleLastName,
                } = conversation.messagesMetaData.providerToScheduleInfo || {};

                const timeZone =
                  clinicIdMapping[conversation.clinicId]?.timeZone;

                const patientFullName =
                  [patientFirstName.trim(), patientLastName.trim()]
                    .filter(Boolean)
                    .join(" ") || "";

                const providerToCancelFullName =
                  [
                    (providerToCancelFirstName || "").trim(),
                    (providerToCancelLastName || "").trim(),
                  ]
                    .filter(Boolean)
                    .join(" ") || "";

                const providerToScheduleFullName =
                  [
                    (providerToScheduleFirstName || "").trim(),
                    (providerToScheduleLastName || "").trim(),
                  ]
                    .filter(Boolean)
                    .join(" ") || "";

                const showDivider =
                  (patientFullName ||
                    newPatient !== undefined ||
                    serviceType ||
                    appointmentToCancelType ||
                    appointmentToScheduleType) &&
                  (patientDateOfBirth ||
                    patientEmail ||
                    providerToCancelFullName ||
                    providerToScheduleFullName ||
                    dateToCancel ||
                    dateToSchedule ||
                    newPatientCreated);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={conversation.conversationId}
                    sx={{ height: "90px", cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/conversations/${conversation.conversationId}`)
                    }
                  >
                    <TableCell
                      key={"1"}
                      align="left"
                      sx={{ maxWidth: "45px", minWidth: "35px" }}
                    >
                      <IconButton
                        disabled={callbackConversationIdsSet.has(
                          conversation.conversationId
                        )}
                        size="small"
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent row click
                          createCallback(
                            conversation.clinicId,
                            conversation.conversationId,
                            conversation.patientNumber
                          );
                        }}
                      >
                        <PhoneCallbackIcon />
                      </IconButton>
                      {formatPhoneNumber(conversation.patientNumber)}
                    </TableCell>
                    <TableCell
                      key={"2"}
                      align="left"
                      sx={{ maxWidth: "120px" }}
                    >
                      {conversation.state === ConversationState.CLOSED && (
                        <>
                          <Box component="span" sx={{ fontWeight: "700" }}>
                            Summary
                          </Box>
                          {": "}
                        </>
                      )}
                      {conversation.state === ConversationState.CLOSED
                        ? conversation.messagesMetaData.additionalInfo
                            .reasonForClosingConversation
                        : truncateText(
                            conversation.messages[
                              conversation.messages.length - 1
                            ].content,
                            150
                          )}
                    </TableCell>
                    <TableCell key={"3"} align="left" sx={{ maxWidth: "75px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            fontSize: "0.75rem",
                          }}
                        >
                          {patientFullName && (
                            <Chip
                              label={`patient name: ${patientFullName}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.patientFullName,
                              }}
                              key={patientFullName}
                            />
                          )}
                          {newPatient !== undefined && (
                            <Chip
                              label={
                                newPatient ? "new patient" : "existing patient"
                              }
                              sx={{
                                backgroundColor:
                                  patientStatusColors[
                                    newPatient ? "New" : "Existing"
                                  ],
                              }}
                              size="small"
                              key={"patientStatus"}
                            />
                          )}
                          {serviceType && (
                            <Chip
                              label={`service type: ${serviceType}`}
                              size="small"
                              sx={{
                                backgroundColor: serviceTypeColors[serviceType],
                              }}
                              key={serviceType}
                            />
                          )}
                          {serviceType === ServiceType.RESCHEDULE_APPOINTMENT &&
                          appointmentToCancelType ===
                            appointmentToScheduleType ? (
                            <Chip
                              label={`appointment type: ${appointmentToScheduleType}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  appointmentTypeColors[
                                    appointmentToScheduleType
                                  ],
                              }}
                              key={appointmentToScheduleType}
                            />
                          ) : (
                            <>
                              {appointmentToCancelType && (
                                <Chip
                                  label={`appointment ${
                                    serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                      ? "to cancel "
                                      : ""
                                  }type: ${appointmentToCancelType}`}
                                  size="small"
                                  sx={{
                                    backgroundColor:
                                      appointmentTypeColors[
                                        appointmentToCancelType
                                      ],
                                  }}
                                  key={appointmentToCancelType}
                                />
                              )}
                              {appointmentToScheduleType && (
                                <Chip
                                  label={`appointment ${
                                    serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                      ? "to schedule "
                                      : ""
                                  }type: ${appointmentToScheduleType}`}
                                  size="small"
                                  sx={{
                                    backgroundColor:
                                      appointmentTypeColors[
                                        appointmentToScheduleType
                                      ],
                                  }}
                                  key={appointmentToScheduleType}
                                />
                              )}
                            </>
                          )}
                        </Box>

                        {showDivider && (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            ----------------------------
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            fontSize: "0.75rem",
                          }}
                        >
                          {patientDateOfBirth && (
                            <Chip
                              label={`patient DOB: ${patientDateOfBirth}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.patientDateOfBirth,
                              }}
                              key={patientDateOfBirth}
                            />
                          )}
                          {patientEmail && (
                            <Chip
                              label={`patient email: ${patientEmail}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.patientEmail,
                              }}
                              key={patientEmail}
                            />
                          )}
                          {providerToCancelFullName && (
                            <Chip
                              label={`${
                                serviceType === ServiceType.CANCEL_APPOINTMENT
                                  ? "provider name: "
                                  : serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                  ? "from: "
                                  : "cancelled for: "
                              } ${providerToCancelFullName}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.providerToCancelFullName,
                              }}
                              key={providerToCancelFullName}
                            />
                          )}
                          {providerToScheduleFullName && (
                            <Chip
                              label={`${
                                serviceType === ServiceType.SCHEDULE_APPOINTMENT
                                  ? "provider name: "
                                  : serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                  ? "to: "
                                  : "scheduled for: "
                              } ${providerToScheduleFullName}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.providerToScheduleFullName,
                              }}
                              key={providerToScheduleFullName}
                            />
                          )}
                          {dateToCancel && (
                            <Chip
                              label={`${
                                serviceType === ServiceType.CANCEL_APPOINTMENT
                                  ? "appointment date: "
                                  : serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                  ? "from: "
                                  : "cancelled date: "
                              } ${moment(dateToCancel)
                                .tz(timeZone || moment.tz.guess())
                                .format("YYYY-MM-DD h:mm a")}`}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.dateToCancel,
                              }}
                              key={dateToCancel}
                            />
                          )}
                          {dateToSchedule && (
                            <Chip
                              label={`${
                                serviceType === ServiceType.SCHEDULE_APPOINTMENT
                                  ? "appointment date: "
                                  : serviceType ===
                                    ServiceType.RESCHEDULE_APPOINTMENT
                                  ? "to: "
                                  : "scheduled date: "
                              } ${moment(dateToSchedule)
                                .tz(timeZone || moment.tz.guess())
                                .format("YYYY-MM-DD h:mm a")}
                            `}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.dateToSchedule,
                              }}
                              key={dateToSchedule}
                            />
                          )}
                          {newPatientCreated && (
                            <Chip
                              label={"patient_created_in_PMS"}
                              size="small"
                              sx={{
                                backgroundColor:
                                  additionalInfoColors.newPatientCreated,
                              }}
                              key={"newPatientCreated"}
                            />
                          )}
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell key={"4"} align="left" sx={{ maxWidth: "15px" }}>
                      <Chip
                        label={conversation.state}
                        sx={{
                          backgroundColor: stateColors[conversation.state],
                          color: "black",
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      key={"5"}
                      align="right"
                      sx={{ maxWidth: "20px" }}
                    >
                      {formatDate(conversation.editTimestamp)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredConversations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ConversationsTable;
